body {
  margin: 0;

  font-family: "Montserrat", "Inter", "Raleway", "Roboto", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--mainBackgroundText);
  height: 100vh;
  height: 100svh;
  width: 100vw;
  background: var(--mainBackground);
  overflow: hidden;
}

textarea {
  font-family: "Raleway", sans-serif;
}

code {
  background-color: var(--translucent);
  width: 100%;
  flex: 1;
  /* padding:25px; */
}

:root {
  --contentWidth: 62vw;
  --headerHeight: 90px;

  --topHeaderHeight: 70px;
  --bottomHeaderHeight: 90px;

  --mainPadding: 25px;
  --headerWidth: 280px;
  --toastify-color-progress-dark: #333333 !important;
}

.MuiInputBase-root:before {
  display: none !important;
}

.MuiInputBase-root {
  overflow: hidden !important;
}

.MuiInputLabel-root.Mui-focused {
  color: var(--color) !important;
  opacity: 0.8;
}

/* .MuiIconButton-edgeEnd {
  color: #999999 !important ;
}

.MuiInput-underline:before {
  border-bottom: 1px solid var(--translucentHard) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #999 !important;
}
.MuiFormLabel-root {
  color: #999 !important;
}





.MuiInputBase-input {
  overflow: hidden !important;
} */

* {
  box-sizing: border-box;
  outline: none;
  font-family: "Montserrat";
  font-weight: 500;
  /* font-size: 21px;
  line-height: 27px; */
}

html,
body {
  overflow-x: hidden;
}

@keyframes strikeAnimation {
  from {
    width: 0%;
  }
  to {
    width: 89%;
  }
}

@keyframes lineContainerFadeAway {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

html {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--translucentHard);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 50px;
}

iframe {
  border: none !important;
}

.chrome-picker {
  width: 100% !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color);
  opacity: 0.8; /* Firefox */
}

@media (max-width: 900px) {
  ::-webkit-scrollbar-thumb:hover {
    width: 5px;
  }

  ::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }
}

/* Animation */

@keyframes fadeIn {
  from {
    opacity: 0;
    /* transform: translateY(20px); */
  }
  to {
    opacity: 1;
    /* transform: translateY(0); */
  }
}

@keyframes centerScaleReveal {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
  }
}

.Toastify__toast-container--top-right {
  z-index: 500;
}
/* @keyframes centerScaleReveal {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
} */
